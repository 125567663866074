html {
	background-color:#FFF;
	    background-image: radial-gradient(circle farthest-side at 10% -10%, rgba(255, 255, 255, 0.49), rgba(255, 255, 255, 0) 55%), radial-gradient(circle at 100% 0%, rgba(173, 153, 232, 0.96) 17%, rgba(173, 153, 232, 0) 69%), radial-gradient(circle at -10% 80%, rgb(171, 98, 211) 15%, rgba(219, 187, 236, 0.37) 74%, rgba(255, 255, 255, 0) 98%), linear-gradient(
153deg
, rgb(243, 236, 250), rgb(144, 103, 224) 40%, rgb(63, 81, 246) 88%);
}

@font-face {
  font-family: 'Axiforma';
  src: url('https://uploads-ssl.webflow.com/5fef53698f7605f5423fd2d7/5fef91d701923a38fff803bf_Kastelov%20-%20Axiforma%20Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Axiforma';
  src: url('https://uploads-ssl.webflow.com/5fef53698f7605f5423fd2d7/5fef91f4d48576f76252cf56_Kastelov%20-%20Axiforma%20ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Axiforma';
  src: url('https://uploads-ssl.webflow.com/5fef53698f7605f5423fd2d7/5fef91e3a081fc8635824020_Kastelov%20-%20Axiforma%20Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Axiforma';
  src: url('https://uploads-ssl.webflow.com/5fef53698f7605f5423fd2d7/5fef920b3d8f860105f8fb52_Kastelov%20-%20Axiforma%20Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Axiforma';
  src: url('https://uploads-ssl.webflow.com/5fef53698f7605f5423fd2d7/5fef921d47d2824567027b9a_Kastelov%20-%20Axiforma%20Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Axiforma';
  src: url('https://uploads-ssl.webflow.com/5fef53698f7605f5423fd2d7/5fef929d9dfbe2a6fd71b242_Kastelov%20-%20Axiforma%20SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Axiforma';
  src: url('https://uploads-ssl.webflow.com/5fef53698f7605f5423fd2d7/5fef92bc3d451acbfbacab99_Kastelov%20-%20Axiforma%20Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

body {
  margin: 0;
  display:flex;
  justify-content: center;
  align-items: center;
  height:100vh;
  font-family:'Axiforma';
}

/* VIEWPORT */

.mountButton {
	position:absolute;
	top:3px;
	right:3px;
	z-index:2
}

.cssRenderer {
	position:absolute;
	top:0px;
	z-index:1;
}

.viewportContainer {
	display:flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.viewport {
	width:100vw;
	height:100vh;
	transition:opacity 1s ease;
	opacity:1;
}

canvas {
	filter: brightness(100%) blur(0px);
	transition: filter 1.5s ease;
}

.viewport.darken canvas {
	filter: brightness(70%) blur(4px);
}

.progress {
	height:5px;
	width:300px;
	position:absolute;
	top:50%;
	margin:auto;
	background:#FFF;
	border-radius:3px;
	display:flex;
	justify-content: flex-start;
	align-content: stretch;
	transition:opacity .5s ease;
}

.progress div {
	background-image: linear-gradient(146deg, rgba(34, 201, 165, 1) 0%, rgba(43, 120, 191, .7) 100%);
	display:block;
	transition:width 1s ease,transform .3s ease, opacity 1s ease;
	transform-origin: left;
	width:100%;
}

/* LABELS */

#labels {
  position: absolute; 
  left: 0;            
  top: 0;
  color: white;
}

.labelContainer {
	width:0px;
	height:0px;
	display:flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	position:relative;
	color:#FFF;
}

.label {
	width:550px;
	height:100px;
	font-size:46px;
	background-color:rgba(255, 255, 255, 0.63);
	border-radius:5px;
	display:flex;
	justify-content: stretch;
	flex-direction: column;
	align-items:stretch;
	transition:border .3s ease-in-out,box-shadow .3s ease-in-out, height .7s ease-in-out;
	cursor:pointer;
	box-shadow:0px 0px 0px 3px rgba(255,255,255,1) inset;
	position:absolute;
	top:-75px;
	text-align:left;
	overflow:hidden;
	backdrop-filter:blur(30px);
	-webkit-backdrop-filter:blur(30px);
	border:rgba(255, 255, 255, 0.4) solid 1px;
  
}

.label:hover {
	box-shadow:0px 0px 0px 3px rgba(50, 87, 255, 1) inset;
	border:rgba(255, 255, 255, 1) solid 1px;
}

.label.open {
	height:550px;
	cursor:default;
	box-shadow:0px 0px 0px 1px rgba(255,255,255,.8) inset;
}

.label.open:hover {
	box-shadow:0px 0px 0px 3px rgba(255,255,255,0) inset;
}

.labelTitle {
	height:100px;
	display:flex;
	justify-content: flex-start;
	align-items: center;
	font-size:31px;
	padding:3px 30px 0px;
	text-align:left;    
	background-image: linear-gradient(146deg, rgba(255,255,255,.4) 0%, rgba(255,255,255,0.12) 100%);
	border-radius:5px;
	transform:translate3d(0,0,-10px) scale(1);
	flex-shrink: 0;
	position:relative;
	z-index:1;
	color:#000;
	text-shadow:0px 0px 10px rgba(0,0,0,.2);
	transition:border .3s ease;
	border:#FFF solid 1px;
	box-shadow:0px 5px 10px rgba(0,0,0,.06);
}

.label.open .labelTitle {
	border-radius:5px 5px 0px 0px;
	}

.labelTitle:hover {
}

.labelButton {
	height:60px;
	width:200px;
	backdrop-filter:blur(20px);
	-webkit-backdrop-filter:blur(20px);
	    background-image: linear-gradient(146deg, #69ecd0, #4d9be4);
	border-radius:5px;
	display:flex;
	justify-content: center;
	align-items: center;
	color:#FFF;
	font-size:19px;
	padding-top:2px;
	box-shadow:0px 0px 0px 1px rgba(255,255,255,.8) inset, 0px 0px 40px rgba(0,0,0,.2);
}

.labelButton:hover {
	cursor:pointer;
	box-shadow:0px 0px 0px 1px rgba(255,255,255,1) inset,0px 0px 40px rgba(0,0,0,.2);
}

.labelText {
	padding:10px 34px;
	font-size:16px;
	color:#000;
	margin-top:250px;
	position:relative;
	font-weight:300;
}

/*
.labelText:before {
	content:'';
	position:absolute;
	display:block;
	width:100%;
	height: 3px;
	top:-5px;
	left:0px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.39) 29%, rgba(255, 255, 255, 0.39) 72%, rgba(255, 255, 255, 0));
}
*/

.labelImage {
/* 	position:absolute; */
/* 	top:120px; */
	width:520px;
	height:190px;
	background:rgba(227, 226, 223, 1);
	display:block;
	border:#FFF solid 1px;
	background-size:100% auto;
	background-position:center;
	background-repeat:no-repeat;
	box-shadow:0px 0px 40px rgba(0,0,0,.2);
}
